import { FlexCard } from '../Card'
import queryString from 'query-string'
import { useQuery, gql } from '@apollo/client'
import { useLocationProvider } from '../LocationProvider/LocationProvider'
import { Spinner, Button } from '@dfds-ui/react-components'
import { SystemIcon } from '@dfds-ui/icons'
import { Link } from 'gatsby'
import { useLocaleContext } from '../LocaleContext'
import { useTranslation } from 'react-i18next'

const NASDAQ_BY_ID_QUERY = gql`
  query NasdaqByIdQuery($id: String!, $lang: String!) {
    nasdaqById(id: $id, lang: $lang) @client {
      message
      title
      attachments {
        filename
        url
      }
    }
  }
`

const AnnouncementItem = () => {
  const location = useLocationProvider()
  const { id, lang } = queryString.parse(location.hash)
  const { data, loading, error } = useQuery(NASDAQ_BY_ID_QUERY, {
    variables: { id, lang },
  })
  const { localeLower } = useLocaleContext()
  const { t } = useTranslation()

  if (loading) return <Spinner />
  if (error) return null
  return (
    <>
      <Button
        as={Link}
        to={`/${localeLower}/about/investors/announcements`}
        variation="text"
        icon={<SystemIcon icon="ArrowBack" />}
        iconAlign="left"
      >
        {t('ANNOUNCEMENT-BACKLINK')}
      </Button>
      <FlexCard>
        <h3>{data.nasdaqById.title}</h3>
        <div dangerouslySetInnerHTML={{ __html: data.nasdaqById.message }} />
      </FlexCard>
    </>
  )
}

export default AnnouncementItem
